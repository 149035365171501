import React, { Component } from 'react';
import './App.css';
import Jungle from './containers/Jungle/Jungle';
import Land from './containers/Land/Land';

class App extends Component {

    render() {
        const jungle = parseInt(Math.random() * 10) % 2 === 0;

        return (
            <div className="App">
              {
                jungle
                ?
                <Jungle />
                :
                <Land />
              }
            </div>
        );
    }
}

export default App;
