import React, { Component } from 'react';

import classes from './Jungle.module.css';
import Contact from '../../components/Contact/Contact';
import Logo from '../../components/Logo/Logo';

class Jungle extends Component {

    state = {
        open: false
    };

    resize = () => this.forceUpdate()

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    toggleContact = () => {
        this.setState( { open: !this.state.open } );
    }

    render () {

        let screenHeight = window.innerHeight;
        let screenWidth = window.innerWidth;
        const imageRatio = 1440 / 900;
        const ratio = screenWidth / screenHeight;
        let backgroundStyle = {};
        let contactStyle = {};

        if (ratio >= imageRatio) {
            const backgroundWidth = screenHeight * 1440 / 900;
            const backgroundHeight = '100%';
            backgroundStyle = { width: backgroundWidth, height: backgroundHeight };
            contactStyle = { 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: (screenWidth - backgroundWidth) / 2 + 'px'
            };
        }
        else {
            const backgroundWidth = '100%';
            const backgroundHeight = screenWidth * 900 / 1440;
            backgroundStyle = { width: backgroundWidth, height: backgroundHeight };
            contactStyle = { 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            };
        }

        return (
            <div className={classes.JungleContainer}>
                {
                    this.state.open
                    ?
                    <div className={classes.Contact}>
                        <div style={contactStyle}><Contact toggle={this.toggleContact}/></div>
                    </div>
                    :
                    null
                }
            <div className={classes.Preload}></div>
                <div className={classes.Jungle} style={backgroundStyle}>
                    
                    <div className={classes.LogoContainer}>
                        <Logo />
                    </div>
                    <button className={classes.ContactLink} onClick={this.toggleContact}>
                        CONTACT
                    </button>
                </div>
            </div>
        );
    }
}

export default Jungle;