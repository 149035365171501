import React, { Component } from 'react';

import classes from './Logo.module.css';
import logoImg from '../../assets/images/done_logo.png';

class Logo extends Component {
    render () {
        return (
            <div className={classes.Logo}>
                <img src={logoImg} alt="Do.n.e logo"/>
            </div>
        );
    }
}

export default Logo;