import React, { Component } from 'react';
import onClickOutside from "react-onclickoutside";

import classes from './Contact.module.css';
import contactImg1 from '../../assets/images/contact_mobile_1.png';
import contactImg2 from '../../assets/images/contact_mobile_2.png';
import footerTop from '../../assets/images/donefooter-barre.png';

class Contact extends Component {

    handleClickOutside = evt => {
        this.props.toggle();
    }

    render () {
        return (
            <div className={classes.ContactContainer}>
                <img src={footerTop} onClick={this.props.toggle} alt="Do.n.e contact" />

                <div className={classes.Contact}>
                    <div className={classes.ContactImages}>
                        <div>
                            <img src={contactImg1} alt="contact" />
                        </div>
                        <div>
                            <img src={contactImg2} alt="contact" />
                        </div>
                    </div>
                    <div className={classes.ContactContent}>
                        <div className={classes.ContactElement}>
                            <div className={classes.ContactSeparator}></div>
                            <div>VALERIE MARENDAZ</div>
                        </div>
                        <div className={classes.ContactElement}>
                            <div className={classes.ContactSeparator}></div>
                            <div><a href="mailto:hello@do-n-e.com">HELLO@DO-N-E.COM</a></div>
                            <div><a href="tel:+33158186777">T +33(0) 1 58 18 67 77</a></div>
                        </div>
                        <div className={classes.ContactElement}>
                            <div className={classes.ContactSeparator}></div>
                            <div>FOR ORDERS</div>
                            <div><a href="mailto:order@do-n-e.com">ORDER@DO-N-E.COM</a></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default onClickOutside(Contact);